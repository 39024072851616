
import Vue from "vue";
var disconnectedNotificaiton = {
    hasNotified: false,
}; 
export default {
    send() {
        if (!disconnectedNotificaiton.hasNotified) {
            disconnectedNotificaiton.hasNotified = true;
            Vue.prototype.$notify({
                message: "Seems like you're disconnected from the internet.",
                timeout: 20000,
                icon: "ni ni-bell-55",
                type: "danger",
            });
            setTimeout(() => {
                disconnectedNotificaiton.hasNotified = false;
            }, 20000);
        }
    }
};
