export class Lock{ 
    isLocked = false;
    timeout = null;

    lock() {
        this.isLocked = true
    }

    unlock() {
        this.isLocked = false
    }

    waitIfLocked(callback, log = false) {
        // if (log) {
        //  console.log(`API call "${callback().url}" is locked`)
        // }
   
        let prom = new Promise((resolve, reject) => {
            if (this.isLocked) {
                return setTimeout(() => {resolve(this.waitIfLocked(callback, true))}, 100)
            } else {
                if (log) {
                    this.isLocked = true // Only relock if in locking queue
                }
                return resolve(callback())
            }
        })
        return prom
    }
}