var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":"img/brand/logos/investRand.png"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Discover',
          path: '/listing/discover',
          icon: 'fas fa-search-dollar text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Service Providers',
          path: '/sp/discover',
          icon: 'fas fa-users text-default',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'My Portfolio',
          path: '/portfolio',
          icon: 'fas fa-seedling text-orange',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Portfolio',
            path: '/portfolio/my-portfolio',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Agreements', path: '/portfolio/my-agreements' }}})],1),(_vm.menu_items.my_listings.show || _vm.menu_items.listing_manager.show)?_c('sidebar-item',{attrs:{"link":{
          name: 'Listings',
          icon: 'ni ni-archive-2 text-orange',
        }}},[(_vm.menu_items.my_listings.show)?_c('sidebar-item',{attrs:{"link":{
            name: 'My Listings',
            path: '/listing/my-listings',
            icon: 'ni ni-archive-2 text-orange',
          }}}):_vm._e(),(_vm.menu_items.listing_manager.show)?_c('sidebar-item',{attrs:{"link":{
            name: 'Manage Platform Listings',
            path: '/listing/manage',
            icon: 'ni ni-bullet-list-67 text-default',
          }}}):_vm._e()],1):_vm._e(),(_vm.menu_items.legal.show)?_c('sidebar-item',{attrs:{"link":{
          name: 'Legal',
          path: '/legal/',
          icon: 'fas fa-balance-scale text-success',
        }}},[(_vm.menu_items.legal.show)?_c('sidebar-item',{attrs:{"link":{
            name: 'User Agreements',
            path: '/legal/agreements-dashboard',
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          icon: 'ni ni-settings text-gray',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Profile', path: '/user/profile' }}})],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{staticClass:"dashboard-content-window",on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }