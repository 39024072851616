import router from "@/routes/router";

export default {
    // User Session Management
    setToken(state, token) {
        localStorage.setItem("token", token);
        state.token = token;
    },
    setLastTokenRefreshNow(state) {
        let last_token_refresh = Date.now(); 
        localStorage.setItem("last_token_refresh", last_token_refresh);
        state.last_token_refresh = last_token_refresh;
    },

    setIsActive(state, value) {
        localStorage.setItem("is_active", JSON.stringify(value));
        state.is_active = value;
    },

    login(state, token) {
        localStorage.setItem("token", token);
        localStorage.setItem("is_login", JSON.stringify(true));
        state.token = token;
        state.is_login = true;
    },

    logout(state) {
        localStorage.removeItem("token");
        localStorage.setItem("is_login", JSON.stringify(false));
        state.token = null;
        state.is_login = false;
        localStorage.removeItem("user_details");
        let user_data = {
            username: "Unknown User",
            email: "",
        };
        state.user = user_data;
        localStorage.setItem("user_details", JSON.stringify(user_data));
        this.commit("clearUser", state);
        this.commit("clearAccount", state);
        
    },
    setUser(state, user) {
        localStorage.removeItem("user_details");
        localStorage.setItem("user_details", JSON.stringify(user));
        state.user = user;
    },

    setAccount(state, account){
        localStorage.removeItem("account");
        localStorage.setItem("account", JSON.stringify(account));
        state.account = account;
    },
    clearUser: (state) => {
        localStorage.removeItem("user_details");
        state.user = {};
    },
    clearAccount: (state) => {
        localStorage.removeItem("account");
        state.account = {};
    },
};
